



































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.listWrap {
  .el-icon-arrow-down {
    font-size: 12px;

    &:before {
      content: "\e6df" !important;
    }
  }

  .el-tabs {
    flex: 1;
    display: flex;
    flex-direction: column;

    .el-tabs__content {
      flex: 1;
    }
  }
}

.btnBox {

  .el-button.is-disabled,
  .el-button.is-disabled:focus,
  .el-button.is-disabled:hover {
    background: #a9a9a9;
    border-color: #a9a9a9;
    color: #fff;
  }
}

.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}

.del-tishi {
  .el-dialog__header {
    background: #ffffff;
    text-align: left;
  }

  .el-dialog__title {
    color: #333;
  }

  .el-dialog__body {
    .theCountdown {
      color: red;
      font-size: 16px;
    }
  }

  .el-dialog__headerbtn {
    .el-dialog__close {
      color: black;
    }
  }
}
